import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { NavigationLinkProvider } from './contexts/NavigationLink';
import routes from './routes';

const router = createBrowserRouter(routes);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NavigationLinkProvider>
        <RouterProvider router={router} />
        <Toaster position='top-right' containerStyle={{ top: 80 }} />
      </NavigationLinkProvider>
    </LocalizationProvider>
  );
}

export default App;
