import { Alert } from '@mui/material';
import axios from 'axios';
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import keycloak from '../keycloak';
import { getUser } from '../services/user';
import CasaLoader from '../shared/components/CasaLoader/CasaLoader';
import User from '../shared/models/User';
import { UserProvider } from './User';

interface Auth {
  currentUser: User | null;
}

const AuthContext = createContext<Auth>({
  currentUser: null,
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const isAuthenticated = await keycloak.init({
          onLoad: 'login-required',
        });

        if (!isAuthenticated) {
          keycloak.clearToken();
          keycloak.login();
          return;
        }

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${keycloak.token}`;

        const user = await getUser();

        setCurrentUser(user);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // useEffect(() => {
  //   currentUser &&
  //     toast.success(
  //       `Welcome back, ${_.capitalize(currentUser.tenant.displayName)}`
  //     );
  // }, [currentUser]);

  if (isLoading) return <CasaLoader />;
  if (error) return <Alert severity='error'>{error}</Alert>;
  if (!currentUser)
    return <Alert severity='error'>Oops! Something went wrong</Alert>;

  return (
    <AuthContext.Provider value={{ currentUser }}>
      <UserProvider currentUser={currentUser}>{children}</UserProvider>
    </AuthContext.Provider>
  );
};

export default AuthContext;
