import Configs from './Config';

const e2eQaConfigs: Configs = {
  dashboardUrl: 'https://dashboard.qa.casaretail.ai',
  ruleServerUrl: 'https://kapi.qa.casaretail.ai',
  casaWebAppUrl: 'https://web.qa.casaretail.ai',
  casaEcomUrl: 'https://ecom.qa.casaretail.ai',
  casaBeatUrl: 'https://beat-api.qa.casaretail.ai',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://qa.auth.ginesys.one/auth`,
    realm: `GinesysOne`,
    clientId: `GinesysOne`
  },
};

export default e2eQaConfigs;
