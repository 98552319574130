import { createSvgIcon } from "@mui/material";

const Subscription = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="8"
      y1="5.6"
      x2="16"
      y2="5.6"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <line
      x1="5"
      y1="7.6"
      x2="19"
      y2="7.6"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <rect
      x="4.4"
      y="10.4"
      width="15.2"
      height="7.2"
      rx="0.6"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <path
      d="M12 12L12.449 13.382H13.9021L12.7265 14.2361L13.1756 15.618L12 14.7639L10.8244 15.618L11.2735 14.2361L10.0979 13.382H11.551L12 12Z"
      fill="currentColor"
    />
  </svg>,
  "Subscription"
);

export default Subscription;
