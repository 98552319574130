import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import CasaLogo from '../../logo/CasaLogo';
import styles from './CasaLoader.module.scss';

const CasaLoader = () => {
  return (
    <Box id={styles.casaLoader}>
      <CasaLogo width={500} />
      <CircularProgress size={16} color='secondary' />
    </Box>
  );
};

export default CasaLoader;
