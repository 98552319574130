import Configs from './Config';

// TODO: Need to change configs
const developmentConfig: Configs = {
  dashboardUrl: 'http://localhost:5335',
  ruleServerUrl: 'http://localhost:3004',
  casaWebAppUrl: 'http://localhost:3020',
  casaEcomUrl: 'https://ecom.casaqa.ajira.tech',
  casaBeatUrl: 'https://beat-api.casaqa.ajira.tech',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `http://localhost:8080/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
};

export default developmentConfig;
