import _ from 'lodash';
import React, { createContext, FC, useMemo, useState } from 'react';

import TenantBrand from '../shared/models/TenantBrand';
import TenantBusiness from '../shared/models/TenantBusiness';
import UserType from '../shared/models/User';

interface UserContextType {
  currentUser: UserType | null;
  selectedBrand: TenantBrand | null;
  selectedBusiness: TenantBusiness | null;
  userPermissions: string[];
  businessUnitNameMap: { [key: string]: TenantBusiness };
  setBrand: (brandId: number) => void;
  setBusiness: (businessId: number) => void;
}

const UserContext = createContext<UserContextType>({
  currentUser: null,
  selectedBrand: null,
  selectedBusiness: null,
  userPermissions: [],
  businessUnitNameMap: {},
  setBrand(brandId: number) {},
  setBusiness(businessId: number) {},
});

export const UserProvider: FC<
  React.PropsWithChildren & { currentUser: UserType }
> = ({ currentUser, children }) => {
  const [selectedBrand, setSelectedBrand] = useState(
    currentUser?.tenant.brands[0] || null
  );
  const [selectedBusiness, setSelectedBusiness] = useState(
    selectedBrand?.businesses[0] || null
  );

  const handleBrandChange = (brandId: number) => {
    const brand = currentUser?.tenant.brands.find(({ id }) => id === brandId);

    if (!brand) {
      throw new Error(`No Brand found for id ${brandId}`);
    } else {
      setSelectedBrand(brand);
      setSelectedBusiness(brand.businesses[0]);
    }
  };

  const handleBusinessChange = (businessId: number) => {
    const business = selectedBrand?.businesses.find(
      ({ id }) => id === businessId
    );

    if (!business) {
      throw new Error(`No Business found for id ${businessId}`);
    } else setSelectedBusiness(business);
  };

  const userPermissions = useMemo(() => {
    return (
      currentUser?.tenant_role_groups.reduce((acc, val) => {
        if (_.isArray(val.permissions)) {
          acc.push(...val.permissions);
          return acc;
        }

        const permissions = Object.values(val.permissions).reduce(
          (acc, { enabled }) => acc.concat(enabled),
          [] as string[]
        );

        acc.push(...permissions);

        return acc;
      }, [] as string[]) || []
    );
  }, [currentUser]);

  const businessUnitNameMap = useMemo(() => {
    return (
      currentUser?.tenant.brands.reduce((acc, val) => {
        val.businesses.forEach((business) => {
          acc[business.name] = business;
        });

        return acc;
      }, {} as { [key: string]: TenantBusiness }) || {}
    );
  }, [currentUser]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        selectedBrand,
        selectedBusiness,
        userPermissions,
        businessUnitNameMap,
        setBrand: handleBrandChange,
        setBusiness: handleBusinessChange,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
