import _ from 'lodash';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import navConfig from '../constants/navigationLinks';
import useUser from '../hooks/useUser';
import NavigationLink from '../shared/models/NavigationLink';

interface NavListContextType {
  navigationLinks: NavigationLink[];
  allowedPages: string[];
}

const NavigationLinkContext = createContext<NavListContextType>({
  navigationLinks: [],
  allowedPages: [],
});

export const useNavigationLink = () => useContext(NavigationLinkContext);

export const NavigationLinkProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userPermissions } = useUser();

  const navigationLinks = useMemo(() => {
    const getAllowedNavList = (list: NavigationLink[]) => {
      return list.reduce((acc, val) => {
        const hasPermission = _.isEmpty(
          _.difference(val.requiredPermissions, userPermissions)
        );

        if (!hasPermission) return acc;
        if (typeof val.content === 'string') acc.push(val);
        if (_.isArray(val.content))
          acc.push({ ...val, content: getAllowedNavList(val.content) });

        return acc;
      }, [] as NavigationLink[]);
    };

    return getAllowedNavList(navConfig);
  }, [userPermissions]);

  const allowedPages = useMemo(() => {
    const getAllowedNavList = (list: NavigationLink[]) => {
      return list.reduce((acc, val) => {
        const hasPermission = _.isEmpty(
          _.difference(val.requiredPermissions, userPermissions)
        );

        if (!hasPermission) return acc;
        if (typeof val.content === 'string') acc.push(val.content);
        if (_.isArray(val.content)) acc.push(...getAllowedNavList(val.content));

        return acc;
      }, [] as string[]);
    };

    return getAllowedNavList(navConfig);
  }, [userPermissions]);

  return (
    <NavigationLinkContext.Provider value={{ navigationLinks, allowedPages }}>
      {children}
    </NavigationLinkContext.Provider>
  );
};

export default NavigationLinkContext;
