import axios from 'axios';
import config from '../config';
import User from '../shared/models/User';

const { ruleServerUrl } = config;

export const getUser = async () => {
  const response = await axios.get<User>(`${ruleServerUrl}/users`);
  return response.data;
};
