import { createSvgIcon } from "@mui/material";

const LabelPriniting = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.54282 18.35L7.29808 12.454L7.74128 12L7.29808 11.546L1.54282 5.65H15.5294C16.3278 5.65 16.9338 5.8984 17.3755 6.32415L23.0786 12L17.3755 17.6758C16.9338 18.1016 16.3278 18.35 15.5294 18.35H1.54282Z"
      stroke="currentColor"
      strokeWidth="1.3"
    />
  </svg>,
  "LabelPriniting"
);

export default LabelPriniting;
