import { ImportExport } from '@mui/icons-material';
import CampaignDelight from '../shared/icons/CampaignDelight';
import Configurations from '../shared/icons/Configurations';
import FieldSalesAssist from '../shared/icons/FieldSalesAssist';
import Flipsell from '../shared/icons/Flipsell';
import FormManagement from '../shared/icons/FormManagement';
import Intelliclose from '../shared/icons/Intelliclose';
import Journify from '../shared/icons/Journify';
import LabelPriniting from '../shared/icons/LabelPriniting';
import Offerio from '../shared/icons/Offerio';
import Pimkart from '../shared/icons/Pimkart';
import Referral from '../shared/icons/Referral';
import Reports from '../shared/icons/Reports';
import RetailCDP from '../shared/icons/RetailCDP';
import Retensee from '../shared/icons/Retensee';
import SocialMediaReward from '../shared/icons/SocialMediaReward';
import Subscription from '../shared/icons/Subscription';
import NavigationLink from '../shared/models/NavigationLink';

import config from '../config';
const { dashboardUrl, casaWebAppUrl } = config;

const navConfig: NavigationLink[] = [
  {
    requiredPermissions: ['analytics_view'],
    label: 'Retail CDP',
    icon: RetailCDP,
    content: [
      {
        content: `${dashboardUrl}/opportunities`,
        label: 'Behavior Analytics',
        requiredPermissions: ['analytics_view'],
        externalLink: true,
      },
      {
        content: [
          {
            content: 'product-analytics/frequency-analysis',
            label: 'Frequency Analysis',
            requiredPermissions: ['analytics_view'],
            externalLink: false,
          },
        ],
        label: 'Products Analytics',
        requiredPermissions: ['analytics_view'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/purchases-clustering-analytics`,
        label: 'Customer Analytics',
        requiredPermissions: ['analytics_view'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/category-analytics`,
        label: 'Store Operations',
        requiredPermissions: ['analytics_view'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/sales-analytics`,
        label: 'Campaign Analytics',
        requiredPermissions: ['analytics_view'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/customer-details/mobile`,
        label: 'Customer 360',
        requiredPermissions: ['analytics_view'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'loyalty'],
    label: 'RetenSee',
    icon: Retensee,
    content: [
      {
        content: `${dashboardUrl}/loyalty-summary`,
        label: 'Loyalty Summary',
        requiredPermissions: ['analytics_view', 'loyalty'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/loyalty-tiers`,
        label: 'Loyalty Tiers',
        requiredPermissions: ['analytics_view', 'loyalty'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/loyalty-tiers-stat`,
        label: 'Loyalty Tiers Stat',
        requiredPermissions: ['analytics_view', 'loyalty'],
        externalLink: true,
      },
      {
        label: 'Loyalty Configuration',
        content: `${dashboardUrl}/loyalty-config`,
        requiredPermissions: ['analytics_view', 'loyalty'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'journey_builder'],
    label: 'Journify',
    icon: Journify,
    content: [
      {
        label: 'Create Journey',
        content: `${casaWebAppUrl}/journey/create`,
        externalLink: true,
        requiredPermissions: ['analytics_view', 'journey_builder'],
      },
      {
        label: 'List Journeys',
        content: `${casaWebAppUrl}/journey/list`,
        externalLink: true,
        requiredPermissions: ['analytics_view', 'journey_builder'],
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'social_media_rewards'],
    label: 'Social Media Rewards',
    icon: SocialMediaReward,
    content: [
      {
        content: `${dashboardUrl}/social-media-rewards/create`,
        label: 'Configure Rewards',
        requiredPermissions: ['analytics_view', 'social_media_rewards'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/social-media-rewards`,
        label: 'Rewards Summary',
        requiredPermissions: ['analytics_view', 'social_media_rewards'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'Offers-management'],
    label: 'Offerio',
    icon: Offerio,
    content: [
      {
        content: `${dashboardUrl}/offers/create`,
        label: 'Create Offer',
        requiredPermissions: ['analytics_view', 'Offers-management'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/offers-analytics`,
        label: 'Offers Analytics',
        requiredPermissions: ['analytics_view', 'Offers-management'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/offers`,
        label: 'Offers List',
        requiredPermissions: ['analytics_view', 'Offers-management'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/offers/search`,
        label: 'Customer Offers Search',
        requiredPermissions: ['analytics_view', 'Offers-management'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'b2b_offers_management'],
    label: 'B2B Offers Flow',
    content: [
      {
        content: `${dashboardUrl}/b2b-offer-flow`,
        label: 'Create B2B Offer Flow',
        requiredPermissions: ['analytics_view', 'b2b_offers_management'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/b2b-offers-report`,
        label: 'B2B Offer Flows Report',
        requiredPermissions: ['analytics_view', 'b2b_offers_management'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'Flipbook'],
    label: 'Flipsell',
    icon: Flipsell,
    content: [
      {
        content: `${dashboardUrl}/flipbook/view-example`,
        label: 'Create/View',
        requiredPermissions: ['analytics_view', 'Flipbook'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['analytics_view', 'label_printing'],
    label: 'Label Printing',
    content: `${dashboardUrl}/labeling`,
    externalLink: true,
    icon: LabelPriniting,
  },
  {
    requiredPermissions: ['lead_manage'],
    label: 'Intelliclose',
    icon: Intelliclose,
    content: [
      {
        requiredPermissions: ['lead_manage', 'lead_create'],
        content: `${dashboardUrl}/new-lead/customerInfo=`,
        label: 'Create New Lead',
        externalLink: true,
      },
      {
        requiredPermissions: ['lead_manage', 'overall-lead-management'],
        content: `${dashboardUrl}/lead-management`,
        label: 'Overall Lead Management',
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/state-manager/walkinFilter/`, // TODO: Url Need to Changed Refer dashboard
        label: 'Manage',
        requiredPermissions: ['lead_manage'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/lead-distribution-analytics`,
        label: 'Distributions',
        requiredPermissions: ['lead_manage'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/lead-closure-analytics`,
        label: 'Closure Analytics',
        requiredPermissions: ['lead_manage'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/lead-conversion-days-analytics`,
        label: 'Conversion Days',
        requiredPermissions: ['lead_manage'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/lead-management-reports`,
        label: 'Reports',
        requiredPermissions: ['lead_manage'],
        externalLink: true,
      },
      {
        content: `${dashboardUrl}/lms-configs`,
        label: 'LMS Master Settings',
        requiredPermissions: ['lead_manage'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    requiredPermissions: ['business-to-business-site'],
    label: 'B2B',
    content: `${'config.b2bUrl.fabindia'}`,
    externalLink: true,
  },
  {
    requiredPermissions: ['commerce-connect'],
    label: 'Pimkart',
    icon: Pimkart,
    content: `${'config.commerceConnect'}/admin/products`,
    externalLink: true,
  },
  {
    requiredPermissions: ['order-management'],
    label: 'B2B Orders',
    content: `${dashboardUrl}http://localhost:3080/admin/orders`,
    externalLink: true,
  },
  {
    requiredPermissions: ['refer_users'],
    label: 'Send Referral Invites',
    icon: Referral,
    content: `${dashboardUrl}/rse-referral`,
    externalLink: true,
  },
  {
    label: 'Campaign Delight',
    icon: CampaignDelight,
    requiredPermissions: ['campaign_manage'],
    content: [
      {
        label: 'Manage Cohorts',
        content: `${dashboardUrl}/manage-cohorts`,
        requiredPermissions: ['campaign_manage'],
        externalLink: true,
      },
      {
        label: 'Create Campaigns',
        content: `${dashboardUrl}/campaign/create`,
        requiredPermissions: ['campaign_manage'],
        externalLink: true,
      },
      {
        label: 'List Campaigns',
        content: `${dashboardUrl}/campaign/list`,
        requiredPermissions: ['campaign_manage'],
        externalLink: true,
      },
      {
        label: 'Latency Based Campaigns',
        content: `${dashboardUrl}/latency-campaign/create`,
        requiredPermissions: ['campaign_manage'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    label: 'Form Management',
    icon: FormManagement,
    requiredPermissions: ['form_manage'],
    content: [
      {
        label: 'Create Forms',
        content: `${dashboardUrl}/campaign-form/create`,
        requiredPermissions: ['form_manage'],
        externalLink: true,
      },
      {
        label: 'List Forms',
        content: `${dashboardUrl}/campaign-form/list`,
        requiredPermissions: ['form_manage'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    label: 'Configurations',
    icon: Configurations,
    requiredPermissions: ['config_manage'],
    content: [
      {
        label: 'Permissions V1',
        content: `${dashboardUrl}/role-config-v1`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'Permissions V2',
        content: `${dashboardUrl}/role-config-v2`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'List Users',
        content: `${dashboardUrl}/user-list`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'Email Configuration',
        content: `${dashboardUrl}/email-config`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'Tenant Configuration',
        content: `${dashboardUrl}/tenant-config`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'SMS Configuration',
        content: `${dashboardUrl}/sms-config`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'Muffler Configuration',
        content: `${dashboardUrl}/muffler-config`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'Stores Configuration',
        content: `${dashboardUrl}/stores-config`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'User Configuration',
        content: `${dashboardUrl}/account-management`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
      {
        label: 'Group Configuration',
        content: `${dashboardUrl}/create-customer-group`,
        requiredPermissions: ['config_manage'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    label: 'Reports',
    icon: Reports,
    requiredPermissions: [],
    content: [
      {
        label: 'Transaction',
        content: `${dashboardUrl}/reports/fraud-report`,
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: 'Fraud',
        content: `${dashboardUrl}/reports/transaction-report`,
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: 'Member (Loyalty)',
        content: `${dashboardUrl}/reports/member-loyalty-report`,
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: 'Member (Bill)',
        content: `${dashboardUrl}/reports/member-bill-report`,
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: 'KPI',
        content: `${dashboardUrl}/reports/member-bill-report`,
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: 'Tier Downgrade',
        content: `${dashboardUrl}/reports/tier-downgrade-report`,
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: 'Campaign',
        content: `${dashboardUrl}/reports/campaign-report`,
        requiredPermissions: [],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    label: 'Subscription',
    icon: Subscription,
    requiredPermissions: ['Flipbook', 'config_manage'],
    content: `${dashboardUrl}/subscription-view`,
    externalLink: true,
  },
  {
    label: 'Import/Export',
    icon: ImportExport,
    requiredPermissions: ['data_manage'],
    content: [
      {
        label: 'Upload Data',
        content: `${dashboardUrl}/data-upload`,
        requiredPermissions: ['data_manage'],
        externalLink: true,
      },
      {
        label: 'Upload Logs',
        content: `${dashboardUrl}/upload-logs`,
        requiredPermissions: ['data_manage'],
        externalLink: true,
      },
      {
        label: 'Large Data Uploads',
        content: `${dashboardUrl}/parallel-upload`,
        requiredPermissions: ['data_manage'],
        externalLink: true,
      },
      {
        label: 'Download User Data',
        content: `${dashboardUrl}/user-data-download`,
        requiredPermissions: ['data_manage'],
        externalLink: true,
      },
    ],
    externalLink: true,
  },
  {
    label: 'Field Sales Assist',
    icon: FieldSalesAssist,
    requiredPermissions: ['sfa'],
    content: `${dashboardUrl}/field-sales-assist`,
    externalLink: true,
  },
];

export default navConfig;
