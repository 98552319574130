import { createSvgIcon } from "@mui/material";

const SocialMediaReward = createSvgIcon(
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7772 0.142416C9.85706 0.0448042 8.93228 -0.00269838 8.00697 0.000118251C4.93937 0.000118251 3.03294 1.95397 3.03294 5.09911V7.23449H0.456082C0.335121 7.23449 0.219115 7.28254 0.133583 7.36807C0.048051 7.4536 0 7.56961 0 7.69057V11.2024C0 11.3234 0.048051 11.4394 0.133583 11.5249C0.219115 11.6104 0.335121 11.6585 0.456082 11.6585H3.03294V18.7004C3.03294 18.8213 3.081 18.9374 3.16653 19.0229C3.25206 19.1084 3.36807 19.1565 3.48903 19.1565H7.11761C7.23857 19.1565 7.35458 19.1084 7.44011 19.0229C7.52564 18.9374 7.5737 18.8213 7.5737 18.7004V11.6585H10.1423C10.2531 11.6585 10.36 11.6183 10.4433 11.5453C10.5265 11.4723 10.5804 11.3715 10.5948 11.2617L11.0481 7.74986C11.0566 7.68549 11.0512 7.62006 11.0323 7.55795C11.0134 7.49584 10.9815 7.43847 10.9387 7.38968C10.8958 7.34089 10.8431 7.30181 10.7839 7.27505C10.7248 7.24829 10.6606 7.23446 10.5957 7.23449H7.5737V5.44756C7.5737 4.56276 7.75157 4.19334 8.85802 4.19334H10.7179C10.8389 4.19334 10.9549 4.14528 11.0404 4.05975C11.126 3.97422 11.174 3.85821 11.174 3.73725V0.594849C11.1741 0.48412 11.1338 0.377153 11.0608 0.293904C10.9878 0.210655 10.887 0.15681 10.7772 0.142416V0.142416ZM10.2618 3.28026L8.85711 3.28117C6.88957 3.28117 6.66153 4.51807 6.66153 5.44756V7.69148C6.66153 7.81229 6.70946 7.92815 6.79479 8.01366C6.88013 8.09917 6.9959 8.14732 7.1167 8.14756H10.0776L9.741 10.7472H7.11761C6.99665 10.7472 6.88065 10.7953 6.79512 10.8808C6.70958 10.9663 6.66153 11.0824 6.66153 11.2033V18.2434H3.94511V11.2033C3.94511 11.0824 3.89706 10.9663 3.81153 10.8808C3.72599 10.7953 3.60999 10.7472 3.48903 10.7472H0.913076V8.14756H3.48903C3.60999 8.14756 3.72599 8.09951 3.81153 8.01398C3.89706 7.92845 3.94511 7.81244 3.94511 7.69148V5.09911C3.94511 2.47756 5.46386 0.912282 8.00697 0.912282C8.92735 0.912282 9.75833 0.962451 10.2618 1.00623V3.28026V3.28026Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_3770_2351)">
      <path
        d="M17.4641 11.7891C17.4791 11.7584 17.5024 11.7324 17.5315 11.7143C17.5605 11.6961 17.594 11.6865 17.6283 11.6865C17.6625 11.6865 17.696 11.6961 17.7251 11.7143C17.7541 11.7324 17.7775 11.7584 17.7925 11.7891L18.4431 13.1078C18.4561 13.1345 18.4754 13.1576 18.4994 13.1751C18.5233 13.1926 18.5512 13.204 18.5806 13.2084L20.0377 13.4197C20.1865 13.4413 20.2471 13.625 20.1383 13.7307L19.0854 14.7579C19.0642 14.7786 19.0483 14.8042 19.0392 14.8325C19.0301 14.8607 19.028 14.8908 19.0331 14.92L19.2814 16.3711C19.287 16.4046 19.2831 16.439 19.2703 16.4704C19.2574 16.5018 19.236 16.529 19.2085 16.5489C19.181 16.5689 19.1484 16.5808 19.1146 16.5832C19.0807 16.5857 19.0468 16.5787 19.0167 16.563L17.7134 15.8775C17.6873 15.8638 17.6583 15.8567 17.6288 15.8567C17.5993 15.8567 17.5702 15.8638 17.5441 15.8775L16.2409 16.563C16.2108 16.5784 16.177 16.5852 16.1433 16.5826C16.1096 16.58 16.0773 16.5681 16.0499 16.5482C16.0226 16.5283 16.0013 16.5012 15.9885 16.4699C15.9756 16.4386 15.9717 16.4044 15.9771 16.3711L16.2255 14.92C16.2307 14.8909 16.2288 14.8609 16.2198 14.8326C16.2109 14.8044 16.1952 14.7787 16.1742 14.7579L15.1172 13.7307C15.093 13.7069 15.0759 13.6769 15.0678 13.6439C15.0597 13.611 15.0609 13.5764 15.0714 13.5441C15.0818 13.5118 15.101 13.4831 15.1269 13.4611C15.1527 13.4392 15.1842 13.4248 15.2178 13.4197L16.6749 13.2084C16.7043 13.204 16.7322 13.1926 16.7561 13.1751C16.7801 13.1576 16.7994 13.1345 16.8124 13.1078L17.4641 11.7891Z"
        fill="currentColor"
      />
      <path
        d="M11.4707 9.6344C11.4707 9.09008 11.6869 8.56805 12.0718 8.18316C12.4567 7.79826 12.9787 7.58203 13.5231 7.58203H21.7325C22.2769 7.58203 22.7989 7.79826 23.1838 8.18316C23.5687 8.56805 23.7849 9.09008 23.7849 9.6344V23.4879C23.7849 23.5807 23.7596 23.6718 23.7119 23.7514C23.6642 23.831 23.5958 23.8962 23.514 23.94C23.4322 23.9838 23.34 24.0046 23.2473 24.0001C23.1546 23.9957 23.0648 23.9662 22.9876 23.9148L17.6278 21.0261L12.268 23.9148C12.1908 23.9662 12.101 23.9957 12.0083 24.0001C11.9156 24.0046 11.8234 23.9838 11.7416 23.94C11.6598 23.8962 11.5914 23.831 11.5437 23.7514C11.496 23.6718 11.4708 23.5807 11.4707 23.4879V9.6344ZM13.5231 8.60822C13.2509 8.60822 12.9899 8.71633 12.7974 8.90878C12.605 9.10122 12.4969 9.36224 12.4969 9.6344V22.5294L17.3436 19.9824C17.4278 19.9264 17.5267 19.8965 17.6278 19.8965C17.729 19.8965 17.8279 19.9264 17.9121 19.9824L22.7587 22.5294V9.6344C22.7587 9.36224 22.6506 9.10122 22.4582 8.90878C22.2657 8.71633 22.0047 8.60822 21.7325 8.60822H13.5231Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3770_2351">
        <rect
          width="16.4189"
          height="16.4189"
          fill="white"
          transform="translate(9.41797 7.58203)"
        />
      </clipPath>
    </defs>
  </svg>,
  "SocialMediaReward"
);

export default SocialMediaReward;
