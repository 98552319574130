import { createSvgIcon } from "@mui/material";

const Intelliclose = createSvgIcon(
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3734_2218)">
      <path
        d="M2.63456 11.9467H2.23456V12.3467V13.2098C2.23446 13.2266 2.22774 13.2427 2.21588 13.2545C2.20412 13.2663 2.1882 13.273 2.17155 13.2732C2.16349 13.2731 2.15551 13.2715 2.14799 13.2683C2.14034 13.2652 2.13337 13.2605 2.12747 13.2546C2.1215 13.2486 2.11686 13.2415 2.11375 13.234C2.11056 13.2262 2.10896 13.2179 2.10898 13.2097H2.10899V13.2083V11.8822C2.10904 11.8655 2.11569 11.8495 2.12754 11.8376C2.13937 11.8258 2.15539 11.8192 2.1721 11.8191H8.4323H8.8323V11.4191V10.6776C8.8323 10.6609 8.83893 10.645 8.85066 10.6332C8.8624 10.6215 8.87839 10.6148 8.89509 10.6148C8.91172 10.6148 8.92767 10.6214 8.93945 10.6332C8.95128 10.6451 8.95787 10.661 8.95787 10.6776V11.4191V11.8191H9.35787H15.6186C15.6353 11.8192 15.6513 11.8258 15.6631 11.8376C15.6749 11.8494 15.6815 11.8653 15.6816 11.882C15.6816 11.8821 15.6816 11.8822 15.6816 11.8823L15.6816 13.2083C15.6816 13.2251 15.675 13.241 15.6633 13.2527C15.6515 13.2645 15.6355 13.2711 15.6189 13.2711C15.6022 13.2711 15.5862 13.2645 15.5745 13.2527C15.5628 13.241 15.5561 13.2251 15.5561 13.2083V12.3467V11.9467H15.1561H2.63456ZM1.70899 11.8819V13.2083L2.17177 11.4191C2.04908 11.4192 1.93145 11.468 1.84469 11.5548C1.75794 11.6416 1.70912 11.7592 1.70899 11.8819Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M11.3376 8.69623V9.19995H6.45273V7.77724C6.45367 7.24339 6.62934 6.72451 6.95293 6.29992C7.27659 5.87523 7.73042 5.56821 8.24504 5.42579L8.8955 5.24577L9.5458 5.42578C10.0604 5.56822 10.5141 5.87525 10.8377 6.29992C11.1612 6.72451 11.3368 7.24336 11.3376 7.77717C11.3376 7.77727 11.3376 7.77738 11.3376 7.77749L11.3376 8.69623ZM9.535 4.65793L8.89543 4.8545L8.25586 4.65793C7.75754 4.50477 7.33075 4.17787 7.05304 3.73664C6.77533 3.29542 6.66518 2.76918 6.74262 2.25363C6.82007 1.73805 7.07992 1.26741 7.47498 0.927254C7.87005 0.587094 8.37411 0.4 8.89546 0.4C9.4168 0.4 9.92081 0.587088 10.3159 0.927254C10.711 1.26742 10.9709 1.73807 11.0483 2.25363C11.1257 2.76918 11.0156 3.29542 10.7379 3.73664C10.4602 4.17785 10.0333 4.50477 9.535 4.65793Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M5.28489 23.0962V23.5999H0.4V22.1767C0.40109 21.6429 0.576858 21.1241 0.900448 20.6995C1.22411 20.2748 1.67785 19.9677 2.19241 19.8252L2.8427 19.645L3.49298 19.8252C4.00756 19.9677 4.46136 20.2748 4.78501 20.6995C5.10858 21.1241 5.28434 21.6428 5.28546 22.1766V22.6962H5.28489V23.0962ZM3.48226 19.0573L2.8427 19.2539L2.20313 19.0573C1.7048 18.9042 1.27802 18.5773 1.0003 18.1361C0.722597 17.6948 0.612449 17.1686 0.68989 16.653C0.767333 16.1375 1.02718 15.6668 1.42224 15.3267C1.81731 14.9865 2.32138 14.7994 2.84273 14.7994C3.36407 14.7994 3.86807 14.9865 4.26315 15.3267C4.65823 15.6668 4.91813 16.1375 4.99557 16.653C5.07301 17.1686 4.96286 17.6948 4.68515 18.1361C4.40745 18.5773 3.98061 18.9042 3.48226 19.0573Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M17.3898 23.0962V23.5999H12.5055V22.1767C12.5066 21.6429 12.6823 21.1241 13.0059 20.6995C13.3296 20.2748 13.7833 19.9677 14.2979 19.8252L14.9482 19.645L15.5985 19.8252C16.113 19.9677 16.5668 20.2748 16.8905 20.6995C17.2141 21.1241 17.3898 21.643 17.3909 22.1768C17.3909 22.1769 17.3909 22.177 17.3909 22.1771L17.3909 22.6962H17.3898V23.0962ZM15.5877 19.0573L14.9482 19.2539L14.3086 19.0573C13.8103 18.9042 13.3834 18.5773 13.1057 18.1361C12.828 17.6948 12.7179 17.1686 12.7953 16.653C12.8727 16.1375 13.1326 15.6668 13.5277 15.3267C13.9228 14.9865 14.4269 14.7994 14.9482 14.7994C15.4695 14.7994 15.9735 14.9865 16.3686 15.3267C16.7637 15.6668 17.0236 16.1375 17.101 16.653C17.1785 17.1686 17.0683 17.6948 16.7906 18.1361C16.5129 18.5773 16.0861 18.9042 15.5877 19.0573Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_3734_2218">
        <rect width="17.7905" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Intelliclose"
);

export default Intelliclose;
