import { createSvgIcon } from "@mui/material";

const Referral = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3770_2330)">
      <path opacity="0.01" d="M24 0H0V24H24V0Z" fill="white" />
      <path opacity="0.01" d="M24 0H0V24H24V0Z" fill="white" />
      <path
        d="M15.4504 19.2314C15.9004 19.6814 16.5004 19.9814 17.2504 19.9814C18.0004 19.9814 18.6004 19.6814 19.0504 19.2314C20.5504 19.2314 21.6004 20.4314 21.6004 21.9314V22.8314C21.6004 23.2814 21.1504 23.7314 20.7004 23.7314H13.8004C13.3504 23.7314 12.9004 23.2814 12.9004 22.8314V21.9314C12.9004 20.5814 14.1004 19.3814 15.4504 19.2314Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2514 18.6C17.9277 18.6 18.5763 18.3313 19.0545 17.8531C19.5327 17.3749 19.8014 16.7263 19.8014 16.05C19.8014 15.3737 19.5327 14.7251 19.0545 14.2469C18.5763 13.7687 17.9277 13.5 17.2514 13.5C16.5751 13.5 15.9265 13.7687 15.4483 14.2469C14.9701 14.7251 14.7014 15.3737 14.7014 16.05C14.7014 16.7263 14.9701 17.3749 15.4483 17.8531C15.9265 18.3313 16.5751 18.6 17.2514 18.6ZM4.95039 7.5C5.40039 7.95 6.00039 8.25 6.75039 8.25C7.50039 8.25 8.10039 7.95 8.55039 7.5C10.0504 7.5 11.1004 8.7 11.1004 10.2V11.1C11.1004 11.55 10.6504 12 10.2004 12H3.30039C2.85039 12 2.40039 11.55 2.40039 11.1V10.2C2.40039 8.7 3.60039 7.5 4.95039 7.5Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75117 6.86855C7.42747 6.86855 8.07608 6.59989 8.55429 6.12168C9.03251 5.64346 9.30117 4.99486 9.30117 4.31855C9.30117 3.64225 9.03251 2.99365 8.55429 2.51543C8.07608 2.03721 7.42747 1.76855 6.75117 1.76855C6.07487 1.76855 5.42627 2.03721 4.94805 2.51543C4.46983 2.99365 4.20117 3.64225 4.20117 4.31855C4.20117 4.99486 4.46983 5.64346 4.94805 6.12168C5.42627 6.59989 6.07487 6.86855 6.75117 6.86855V6.86855Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9492 5.5498H14.9992C16.1992 5.5498 17.2492 6.5998 17.2492 7.7998V9.7498"
        stroke="#6F6F6F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3 18.45H9.25C8.05 18.45 7 17.4 7 16.2V14.25M16.45 9L17.65 10.2L18.85 9H16.45Z"
        stroke="#6F6F6F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80039 14.9998L6.60039 13.7998L5.40039 14.9998"
        stroke="#6F6F6F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1_3770_2330)">
        <path
          d="M11.5 8.4375C9.80879 8.4375 8.4375 9.80879 8.4375 11.5C8.4375 13.1912 9.80879 14.5625 11.5 14.5625C13.1912 14.5625 14.5625 13.1912 14.5625 11.5C14.5625 9.80879 13.1912 8.4375 11.5 8.4375ZM11.5 14.043C10.0959 14.043 8.95703 12.9041 8.95703 11.5C8.95703 10.0959 10.0959 8.95703 11.5 8.95703C12.9041 8.95703 14.043 10.0959 14.043 11.5C14.043 12.9041 12.9041 14.043 11.5 14.043Z"
          fill="currentColor"
        />
        <path
          d="M11.5 8.95703C10.0959 8.95703 8.95703 10.0959 8.95703 11.5C8.95703 12.9041 10.0959 14.043 11.5 14.043C12.9041 14.043 14.043 12.9041 14.043 11.5C14.043 10.0959 12.9041 8.95703 11.5 8.95703ZM12.8125 11.6641C12.8125 11.6941 12.7879 11.7187 12.7578 11.7187H11.7187V12.7578C11.7187 12.7879 11.6941 12.8125 11.6641 12.8125H11.3359C11.3059 12.8125 11.2812 12.7879 11.2812 12.7578V11.7187H10.2422C10.2121 11.7187 10.1875 11.6941 10.1875 11.6641V11.3359C10.1875 11.3059 10.2121 11.2812 10.2422 11.2812H11.2812V10.2422C11.2812 10.2121 11.3059 10.1875 11.3359 10.1875H11.6641C11.6941 10.1875 11.7187 10.2121 11.7187 10.2422V11.2812H12.7578C12.7879 11.2812 12.8125 11.3059 12.8125 11.3359V11.6641Z"
          fill="currentColor"
        />
        <path
          d="M12.7578 11.2812H11.7188V10.2422C11.7188 10.2121 11.6941 10.1875 11.6641 10.1875H11.3359C11.3059 10.1875 11.2812 10.2121 11.2812 10.2422V11.2812H10.2422C10.2121 11.2812 10.1875 11.3059 10.1875 11.3359V11.6641C10.1875 11.6941 10.2121 11.7188 10.2422 11.7188H11.2812V12.7578C11.2812 12.7879 11.3059 12.8125 11.3359 12.8125H11.6641C11.6941 12.8125 11.7188 12.7879 11.7188 12.7578V11.7188H12.7578C12.7879 11.7188 12.8125 11.6941 12.8125 11.6641V11.3359C12.8125 11.3059 12.7879 11.2812 12.7578 11.2812Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3770_2330">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_3770_2330">
        <rect width="7" height="7" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>,
  "Referral"
);

export default Referral;
