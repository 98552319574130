import { Box, Container } from '@mui/material';
import { Suspense } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import PageLoader from '../../../shared/components/PageLoader/PageLoader';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  // const [isSidebarActive, setIsSidebarActive] = useState(false);

  // const handleToggleSidebar = (): void => {
  //   setIsSidebarActive((isSidebarActive) => !isSidebarActive);
  // };

  return (
    <Box id={styles.dashboard}>
      {/* <Navbar onToggleSidebar={handleToggleSidebar} /> */}
      <Box className={styles.app}>
        {/* <Sidebar
          isSidebarActive={isSidebarActive}
          onCloseSidebar={setIsSidebarActive.bind(null, false)}
        /> */}
        <Suspense fallback={<PageLoader />}>
          <Container component='main' className={styles.container}>
            <Outlet />
          </Container>
        </Suspense>
      </Box>
      <ScrollRestoration />
    </Box>
  );
};

export default Dashboard;
