import { lazy } from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

const FrequencyAnalysis = lazy(
  () => import('../components/FrequencyAnalysis/FrequencyAnalysis')
);
const ProductAnalytics = lazy(() => import('../components/ProductAnalytics'));

const productAnalyticsRoute: RouteObject = {
  path: 'product-analytics',
  element: <ProductAnalytics />,
  children: [
    {
      index: true,
      element: <Navigate to='./frequency-analysis' replace />,
    },
    {
      path: 'frequency-analysis',
      element: <FrequencyAnalysis />,
    },
  ],
};

export default productAnalyticsRoute;
