import qaConfigs from './qa';
import developmentConfig from './development';
import Configs from './Config';
import e2eQaConfigs from './e2eqa';
import productionConfigs from './production';

type ConfigMap = { [env: string]: Configs };

const configsMap: ConfigMap = {
  qa: qaConfigs,
  dev: developmentConfig,
  e2eqa: e2eQaConfigs,
  production: productionConfigs,
};

const getEnvConfigs = () => {
  const currentEnv = process.env.REACT_APP_DEPLOY_ENV || 'dev';

  if (!currentEnv || !configsMap[currentEnv]) {
    throw new Error('No Env Found');
  }

  return configsMap[currentEnv];
};

export default getEnvConfigs();
