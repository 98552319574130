import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import productAnalyticsRoute from '../features/ProductAnalytics/routes';

import DashboardLayout from '../layouts/Dashboard';

const routesPages: RouteObject[] = [
  productAnalyticsRoute,
  {
    path: '/',
    element: <Navigate to='/product-analytics/frequency-analysis' />,
  },
  {
    path: '*',
    element: <Navigate to='/product-analytics/frequency-analysis' />,
  },
];

const Routes: RouteObject[] = [
  {
    path: '/',
    element: <DashboardLayout />,
    //TODO: Need to add page guard
    children: routesPages,
  },
];

export default Routes;
