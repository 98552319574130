import { createSvgIcon } from "@mui/material";

const CampaignDelight = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0003 21.1431C17.0497 21.1431 21.1431 17.0497 21.1431 12.0003C21.1431 6.95082 17.0497 2.85742 12.0003 2.85742C6.95082 2.85742 2.85742 6.95082 2.85742 12.0003C2.85742 17.0497 6.95082 21.1431 12.0003 21.1431Z"
      stroke="currentColor"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14286 10.2857C9.77404 10.2857 10.2857 9.77404 10.2857 9.14286C10.2857 8.51167 9.77404 8 9.14286 8C8.51167 8 8 8.51167 8 9.14286C8 9.77404 8.51167 10.2857 9.14286 10.2857Z"
      fill="currentColor"
    />
    <path
      d="M14.8577 10.2857C15.4889 10.2857 16.0006 9.77404 16.0006 9.14286C16.0006 8.51167 15.4889 8 14.8577 8C14.2265 8 13.7148 8.51167 13.7148 9.14286C13.7148 9.77404 14.2265 10.2857 14.8577 10.2857Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42969 14.2861C8.19083 16.5718 9.7154 17.7147 12.0011 17.7147C14.2868 17.7147 15.8114 16.5718 16.5725 14.2861H7.42969Z"
      stroke="currentColor"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "CampaignDelight"
);

export default CampaignDelight;
