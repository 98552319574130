import { createSvgIcon } from '@mui/material';

const FieldSalesAssist = createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.4441 20.9444L4.99187 15.6937C4.93001 15.6149 4.86878 15.5355 4.80821 15.4557C4.04769 14.4539 3.63683 13.2302 3.63868 11.9724C3.63868 10.4327 4.25032 8.95609 5.33905 7.86736C6.42778 6.77863 7.90441 6.16699 9.4441 6.16699C10.9838 6.16699 12.4604 6.77863 13.5492 7.86736C14.6379 8.95609 15.2495 10.4327 15.2495 11.9724C15.2514 13.2296 14.8407 14.4527 14.0805 15.4541L14.08 15.4557C14.08 15.4557 13.9217 15.6636 13.8979 15.6916L9.4441 20.9444ZM5.65052 14.8197C5.65158 14.8197 5.77402 14.9823 5.80199 15.0171L9.4441 19.3126L13.091 15.0113C13.1142 14.9823 13.2382 14.8181 13.2382 14.8181C13.8595 13.9996 14.1952 13 14.194 11.9724C14.194 10.7127 13.6936 9.50451 12.8028 8.61374C11.912 7.72296 10.7039 7.22252 9.4441 7.22252C8.18435 7.22252 6.9762 7.72296 6.08542 8.61374C5.19464 9.50451 4.69421 10.7127 4.69421 11.9724C4.6931 13.0006 5.0287 14.0008 5.65052 14.8197Z'
      fill='currentColor'
    />
    <path
      d='M12.0823 14.6107H11.0268V10.3885H7.86022V14.6107H6.80469V10.3885C6.80497 10.1087 6.91626 9.84036 7.11415 9.64247C7.31204 9.44458 7.58036 9.33329 7.86022 9.33301H11.0268C11.3067 9.33329 11.575 9.44458 11.7729 9.64247C11.9708 9.84036 12.0821 10.1087 12.0823 10.3885V14.6107Z'
      fill='currentColor'
    />
    <path
      d='M8.91602 13.5554H9.97155V14.6109H8.91602V13.5554ZM8.91602 11.4443H9.97155V12.4999H8.91602V11.4443Z'
      fill='currentColor'
    />
    <path
      d='M19.7248 6.80022C19.5559 6.75799 19.387 6.67355 19.2603 6.54689C19.1337 6.50467 19.0915 6.378 19.0915 6.29356C19.0915 6.20912 19.1337 6.08245 19.2181 6.04023C19.3448 5.95579 19.4715 5.87135 19.5981 5.91357C19.8514 5.91357 20.0626 6.04023 20.1892 6.20912L20.5692 5.70246C20.4425 5.5758 20.3159 5.49136 20.1892 5.40691C20.0626 5.32247 19.8937 5.28025 19.7248 5.28025V4.68915H19.2181V5.28025C19.007 5.32247 18.7959 5.44914 18.627 5.61802C18.4581 5.82913 18.3315 6.08245 18.3737 6.33578C18.3737 6.58911 18.4581 6.84244 18.627 7.01132C18.8381 7.22243 19.1337 7.34909 19.387 7.47576C19.5137 7.51798 19.6826 7.60242 19.8092 7.68686C19.8937 7.77131 19.9359 7.89797 19.9359 8.02463C19.9359 8.1513 19.8937 8.27796 19.8092 8.40462C19.6826 8.53129 19.5137 8.57351 19.387 8.57351C19.2181 8.57351 19.007 8.53129 18.8804 8.40462C18.7537 8.32018 18.627 8.19352 18.5426 8.06685L18.1204 8.53129C18.247 8.70017 18.3737 8.82684 18.5426 8.9535C18.7537 9.08017 19.007 9.20683 19.2603 9.20683V9.75571H19.7248V9.12239C19.9781 9.08017 20.1892 8.9535 20.3581 8.78462C20.5692 8.57351 20.6959 8.23574 20.6959 7.94019C20.6959 7.68686 20.6114 7.39131 20.4003 7.22243C20.1892 7.01132 19.9781 6.88466 19.7248 6.80022V6.80022ZM19.4715 3.84473C17.6137 3.84473 16.0938 5.36469 16.0938 7.22243C16.0938 9.08017 17.6137 10.6001 19.4715 10.6001C21.3292 10.6001 22.8492 9.08017 22.8492 7.22243C22.8492 5.36469 21.3292 3.84473 19.4715 3.84473ZM19.4715 10.1357C17.867 10.1357 16.5582 8.82684 16.5582 7.22243C16.5582 5.61802 17.867 4.30916 19.4715 4.30916C21.0759 4.30916 22.3847 5.61802 22.3847 7.22243C22.3847 8.82684 21.0759 10.1357 19.4715 10.1357V10.1357Z'
      fill='currentColor'
    />
  </svg>,
  'FieldSalesAssist'
);

export default FieldSalesAssist;
